import { AuthService } from '@auth0/auth0-angular';
import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  isLogged = false;
  constructor (private auth:AuthService){
    this.auth.user$.subscribe(
      (profile) => {
        if (profile) {
        if (profile.email && profile.email != '') {
                  this.init(profile.email);
                  this.isLogged = true;
                  this.setPeople({
                    "$email": profile.email,    // only special properties need the $
                    "USER_ID": profile.email
                })
                }
              }
      }
    );
  }

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string): void {
    mixpanel.init(environment.mixpanel_token);
    mixpanel.identify(userToken);
  }

    /**
   * Identify User
   *
   * @param {string} alias
   */
     identify(alias: string) {
      mixpanel.identify(alias);
    }

   /**
   * setPeople
   * Store user profiles in Mixpanel's People Analytics product
   * @param {Object} properties
   */
    setPeople(properties: any = {}): void {
      mixpanel.people.set(properties);
    }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    if(this.isLogged) {
      mixpanel.track(id, action);
    }

  }
}