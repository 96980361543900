import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import {GeolocationService, GEOLOCATION_SUPPORT} from '@ng-web-apis/geolocation';
interface IResizeImageOptions {
  maxSize: number;
  file: File;
}
const resizeImage = (settings: IResizeImageOptions) => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');
  const dataURItoBlob = (dataURI: string) => {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
        atob(dataURI.split(',')[1]) :
        unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], {type:mime});
  };
  const resize = () => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
        if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
        }
    } else {
        if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
        }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    let dataUrl = canvas.toDataURL('image/jpeg');
    return dataURItoBlob(dataUrl);
  };

  return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error("Not an image"));
        return;
      }

      reader.onload = (readerEvent: any) => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
  })
};

@Component({
  selector: 'app-installminisense',
  templateUrl: './installminisense.page.html',
  styleUrls: ['./installminisense.page.scss'],
})
export class InstallminisensePage implements OnInit {


  nextStep(): void { this.stepper._steps.first.completed = true; this.stepper.next(); }
  barcodeValue;
  isScan = false;
  firstFormGroup: FormGroup;
  listSite;
  geolocAvailable = false;
  currentIndex: number;
  docs = [];
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  isLoading= false;
  listVegetaux;
  serial="";
  checkMinisense = {status : 2};
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  parentSubject:Subject<any> = new Subject();
  @ViewChild("lestepper",{static:false}) stepper: MatStepper;
  @ViewChild("step1",{static:false}) etape_1: ComponentChoixMinisense;
  @ViewChild("step2",{static:false}) etape_2: ComponentTestReseauMinisense;
  @ViewChild("step3",{static:false}) etape_3: ComponentChoixProjet;
  @ViewChild("step4",{static:false}) etape_4: ComponentChoixVegetal;
  @ViewChild("step5",{static:false}) etape_5: ComponentChoixDispo;
  @ViewChild("step6",{static:false}) etape_6: ComponentPhotosFirst;
  @ViewChild("step7",{static:false}) etape_7: ComponentPhotosTwo;
  @ViewChild("step8",{static:false}) etape_8: ComponentGps;
  deviceInfo = null;
  isChromeBrowser = false;







  selectedStepIndex: number = 0;
  // stepsControls: boolean[] = [true, true, true];
   challengeSteps: boolean[] = [];

 get frmStepOne() {
      return this.etape_1 ? this.etape_1.frm : null;
  }

  get frmStepTwo() {
      return this.etape_2 ? this.etape_2.frm : null;
  }

  get frmStepDispositif() {
    return this.etape_5 ? this.etape_5.frm : null;
}


    get frmStepThree() {
      return this.etape_3 ? this.etape_3.frm : null;
  }

  get frmStepFour() {
      return this.etape_4 ? this.etape_4.frm : null;
  }

  get frmStepFive() {
    return this.etape_5 ? this.etape_4.frm : null;
}

get frmStepSix() {
  return this.etape_6 ? this.etape_5.frm : null;
}

get frmStepSeven() {
  return this.etape_7 ? this.etape_6.frm : null;
}

get frmStepEight() {
  return this.etape_8 ? this.etape_7.frm : null;
}






  constructor(
    private _formBuilder: FormBuilder,
    private us:EVService,
    private toastController:ToastController,
    public readonly geolocation$: GeolocationService,
    private deviceService: DeviceDetectorService,
    private modalController: ModalController,
    public utils : UtilsService) {
      geolocation$.subscribe(position => this.checkGeoloc(position),error => this.handleError(error));

      for (let entry of dispoTypeList) {

        this.docs.push({
          url: entry.img,
          image: entry.img ,
          title: entry.label
        })
    }


    }

    reload() {
      window.location.reload();
    }



    async presentToastWithOptions() {
      const toast = await this.toastController.create({
        header: 'Attention',
        message: 'L\'application n\'arrive pas à vous géolocaliser,<br>Avant de continuer l\'installation nous vous conseillons de regarder l\'aide.',
        color:'warning',
        position: 'top',
        buttons: [

          {
            text: 'Voir aide',
            handler: () => {
              this.utils.presentModalHelp();
            }
          }, {
            text: 'Fermer',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }
        ]
      });
      await toast.present();

      const { role } = await toast.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    }

    handleError(error) {
      this.geolocAvailable = false;
      // if (error.code == 1) {
      //  this.presentToastWithOptions();
      // }
    }

    checkGeoloc(po) {
      this.geolocAvailable = true;
      console.log(po);
    }

    stepChangeCallback(event: StepperSelectionEvent): void {
      if (event.selectedIndex === 1 && event.selectedIndex > event.previouslySelectedIndex) {
          this.challengeSteps = [true];
      }
    }

    ngOnInit() {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      console.log(this.deviceInfo);
      console.log(isMobile);
      console.log(isTablet);
      console.log(isDesktopDevice);
      if(this.deviceInfo.browser == 'Chrome') {
        this.isChromeBrowser = true;
      //   navigator.permissions && navigator.permissions.query({name: 'geolocation'}).then((PermissionStatus) =>{
      //     if(PermissionStatus.state == 'granted'){
      //           this.geolocAvailable = true;
      //     }else{
      //       this.geolocAvailable = false;
      //     }
      // })
      navigator.geolocation.watchPosition((position) =>{
        //console.log("i'm tracking you!");
        this.geolocAvailable = true;
      },
      (error) =>{
        if (error.code == error.PERMISSION_DENIED)
          console.log("you denied me :-(");
          this.geolocAvailable = false;
      });
      }
        this.firstFormGroup = this._formBuilder.group({
          firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
          secondCtrl: ['', Validators.required]
        });
        this.thirdFormGroup = this._formBuilder.group({
            thirdCtrl: ['', Validators.required]
          });
      }

      parent_changeResau(event){

        this.parentSubject.next(event);
    this.nextStep();
      }


  changeSite(event){

  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }

  choixminisense(event) {

    this.parentSubject.next(event);
    this.nextStep();
  }

  viewdoc (event) {
    let idx = 0;

    if (event == 37  ) {
      idx = 0;
      } else if (event == 1 ) {
    idx = 1;
    }else if (event == 39  ) {
    idx = 2;
    } else if (event == 2  ) {
    idx = 3;
    } else if (event == 32  ) {
    idx = 4;
    }else if (event == 31  ) {
    idx = 5;
    }else if (event == 26  ) {
    idx = 6;
    } else if (event == 47  ) {
      idx = 7;
      } else if (event == 36  ) {
        idx = 8;
        } else if (event == 28  ) {
          idx = 9;
          } else if (event == 49  ) {
            idx = 10;
            } else if (event == 28  ) {
              idx = 11;
              } else if (event == 40  ) {
                idx = 12;
                } else {
    idx = event;
    }

  this.selectedImageIndex = idx;
  this.showFlag = true;
  }

  listSiteChange(event) {

    this.parentSubject.next(event);
  }


  parent_choixDispo(event) {

    this.parentSubject.next(event);
  }

  parent_photos_done(event) {

    this.parentSubject.next(event);
  }

  parent_photos_two_done(event) {

    this.parentSubject.next(event);
  }

  fin(event) {

    this.stepper.reset();
  }







  parent_choixVegetal(event) {

    this.parentSubject.next(event);
  }

  progressChangeMain(event) {

   this.selectedStepIndex=1;
   this.parentSubject.next(event);
  }




  async setMinisense(id_centrale,id_suj,id_type_dispositif){
    let obj = {
          id_centrale: id_centrale,
          id_suj:id_suj,
          id_type_dispositif:id_type_dispositif
      }
    const modal = await this.modalController.create({
      component: FinInstallationPage,
      cssClass: 'my-custom-class',
      componentProps: { minisense: obj }
    });
    return await modal.present();
  }
  cancelScan() {
    this.isScan = false;
  }
}






import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import { Subject, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
@Component({
  selector: "choix-minisense",
  template: `
  <h3 class="boldSf">Identification du minisense</h3>
  <!-- <ion-item>
  <ion-icon name="alert-outline"></ion-icon>
  <ion-label class="ion-text-wrap">
    <ion-text>
    Il faut mettre le Minisense sous tension avant de procéder à l'installation.
    </ion-text>

  </ion-label>

  </ion-item> -->
  <form [formGroup]="frm" id="checkDIspo">
  <ng-template matStepLabel>id</ng-template>






  <mat-form-field *ngIf="!isLoading" class="example-full-width" >

    <input matInput placeholder="Numero de serie" formControlName="some" required [(ngModel)]="serial" id="miniserial">
    <button  mat-button matSuffix mat-icon-button *ngIf="!isLoading && isScanQr != true"  (click)="scanQR()"><ion-icon name="scan-outline"></ion-icon>Scanner</button>  <button mat-button matSuffix mat-icon-button *ngIf="isScanQr == true" (click)="isScanQr = false"><ion-icon name="stop-outline"></ion-icon>Fermer scan</button>
    <input   type="hidden" formControlName="resultCheck" required>
  </mat-form-field>


  <div class="example-button-row" *ngIf="!isLoading">
    <button  mat-flat-button color="primary"  [disabled]="!this.frm.value.some" id="dispo_check"  (click)="checkSerial(serial)" >Vérifier la disponibilité</button>
    <button mat-flat-button style="margin-left:2%;"  color="primary"   [hidden]="frm.invalid"  matStepperNext >Suivant</button>
  </div>



  <zxing-scanner [hidden]="!isScanQr" [enable]="isScanQr" (scanSuccess)="scanSuccessHandler($event)" style="padding:2%;"></zxing-scanner>
</form>


    `

})

export class ComponentChoixMinisense  {

  frm: FormGroup;
  resultCheck= false;
  isScan = false;
  @Output() device = new EventEmitter();
  @Output()
  choixminisense: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;
  isScanQr = false;
  serial="";
  isLoading: boolean = false;
  lastTrame: any;
  constructor( private ls:LoadingService,public alertController: AlertController,private utils : UtilsService,private _formBuilder: FormBuilder,private us:EVService) {
    this.frm = this._formBuilder.group({
      some: ['', Validators.required],
      someId: ['', Validators.required],
      resultCheck: ['', Validators.required]
    });
  }

  scanQR() {
    this.isScanQr = true;
  }




scanSuccessHandler(code) {

  this.isScanQr = false;
  this.frm.patchValue({some: code})
}

async presentAlert(data) {
  let sondeAlert = "";
  let str= "<ul>";
  data.sujets_equipes.forEach(function (value) {
    str+="<li>"+value.nom_suj+ " - " +value.nom+ " (Projet : " +value.nom_sit+ ")</li>";

});

if (data.total_sonde > 6) {
  sondeAlert+="Il n'y a plus aucune sonde disponible sur  ce Minisense, merci de faire une maintenance sur un sujet équipé afin d'en libérer";
}

str+="</ul>";
  const alert = await this.alertController.create({
    cssClass: 'my-custom-class',
    header: 'Attention',
    subHeader: '',
    message: 'Ce minisense ('+data.device_nom+') équipe déjà les sujets suivants. ' + str + '' + sondeAlert,
    buttons: ['OK']
  });

  await alert.present();
}





  async checkSerial(code){
    this.isLoading = true;
    this.ls.loadingPresent('Vérification du minisense ' + code + ' en cours');
    this.us.v6_enrollMinisense(code).subscribe(
        result => {
          this.ls.loadingDismiss();
          this.isLoading = false;

         if(result.device_id == 5) {
         // this.utils.showError('Nous ne reconnaissons pas ce minisence, merci de retenter l\'opération','ERREUR');
         this.utils.presentToast('Nous ne reconnaissons pas ce minisence, merci de retenter l\'opération',2000,'warning');
           this.frm.patchValue({some: ""})



         }else if (result.status != 0) {
         //on verifie que le minisense n'equipe pas deja des sujets
          if (result.sujets_equipes.length > 0) {
            this.frm.patchValue({some: ""})
              this.presentAlert(result);
          }else {
             //On lance test de réseau
          this.choixminisense.emit(result);
          this.frm.patchValue({resultCheck: "OK",some: result.device_nom,someId: result.device_id});
          }


         }else if (result.status == 0) {
          this.utils.presentToast('Nous ne reconnaissons pas ce minisence, merci de retenter l\'opération',2000,'warning');
         }



      }
      );
  }
}

export interface Site {
  nom_sit: string;
  id_sit:number
}

@Component({
  selector: "choix-projet",
  template: `
  <ion-item>
    <ion-label>
      <ion-text>
      <h5 *ngIf="objminisense?.device">Minisense : {{objminisense?.device.device_nom}}</h5>
      </ion-text>
      <ion-text>
      <h5 *ngIf="objminisense">Connectivité : {{objminisense?.netWork}}</h5>
      </ion-text>
    </ion-label>
  </ion-item>



  <form [formGroup]="frm">
  <ng-template matStepLabel>Choisir un projet</ng-template>
  <ion-item>
  <ion-label class="ion-text-wrap">
    <ion-text class="boldSf">
    Choix du projet
    </ion-text>
    <mat-form-field class="example-full-width" style="display:block !important;">
    <input matInput id="choix_projet"
           placeholder="Projet"
           aria-label="Projet"
           name="projet"
           [matAutocomplete]="auto"
           [formControl]="stateCtrl">
    <mat-autocomplete #auto="matAutocomplete" >
    <mat-option *ngFor="let site of filteredOptions | async" [value]="site.nom_sit" (onSelectionChange)="progressV(site)">
         {{site.nom_sit}}
        </mat-option>
      <!-- <mat-option *ngFor="let site of filteredOptions | async" [value]="site.nom_sit" (onSelectionChange)="progressV(site)">

        <span>{{site.nom_sit}}</span>

      </mat-option> -->
    </mat-autocomplete>
  </mat-form-field>
  <p><button mat-flat-button color="accent" matStepperPrevious>Retour</button>
  <button style="margin-left:2%;" mat-flat-button color="primary" [disabled]="frm.invalid" matStepperNext>Suivant</button></p>
</ion-label>
</ion-item>



  <!-- <mat-form-field>
  <mat-select required formControlName="some" (selectionChange)="progressV($event)">
        <mat-option *ngFor="let site of listSite" [value]="site">
          {{site.nom_sit}}
        </mat-option>
      </mat-select>


  </mat-form-field> -->



</form>

    `

})



export class ComponentChoixProjet {

  frm: FormGroup;
  isLoading = false;
  listSite;
  @Output() listchange: EventEmitter<number> = new EventEmitter<number>();
  stateCtrl = new FormControl();
  filteredOptions: Observable<any[]>;
  @Input()
  parent:Subject<any>;
  objminisense: any;


  constructor(private _formBuilder: FormBuilder,private us:EVService) {
    this.frm = this._formBuilder.group({
      some: [void 0, Validators.required]
    });
  }

  private _filter(value: string): any {
    console.log(value);
    const filterValue = value.toLowerCase();

   return this.listSite.filter(clients => clients.nom_sit.toLowerCase().includes(filterValue));
  }

  ngOnInit() {
    this.getSites();
    this.parent.subscribe(event => {

      if (event.device) {
        this.objminisense = event;
      }



    // called when the notifyChildren method is
    // called in the parent component
  });

  }

  progressV(event) {

    if (event.nom_sit) {
      this.objminisense.id_sit = event.id_sit;
      this.objminisense.nom_sit = event.nom_sit;
      this.listchange.emit(this.objminisense);
      this.frm.patchValue({some: event.nom_sit})
    }

  }

  getSites() {
    this.us.v6_projets()
      .subscribe((res: any) => {
        this.listSite = res;
        this.filteredOptions = this.stateCtrl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        // this.filteredStates = this.stateCtrl.valueChanges
        // .pipe(
        //   startWith(''),
        //    map(state => state ? this._filterStates(state) : this.listSite.slice())
        // );
      }, () => {
        //this.showError('Erreur', 'Une erreur est survenue durant la réception des sites.');
        return;
      });
  }
}

@Component({
  selector: "choix-vegetal",
  template: `



  <ion-item>
    <ion-label>
      <ion-text>
      <h5 *ngIf="minisenseobj?.device.device_nom">Minisense {{minisenseobj?.device.device_nom}} </h5>
      </ion-text>
      <ion-text>
      <h5 *ngIf="minisenseobj?.netWork" >Connectivité : {{minisenseobj?.netWork}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.nom_sit">Projet : {{minisenseobj?.nom_sit}} </h5>
      </ion-text>
    </ion-label>
  </ion-item>
  <ion-item>
  <ion-label class="ion-text-wrap">
    <ion-text class="boldSf">
Choisir un végétal à équiper
</ion-text>
<form [formGroup]="frm">
  <div *ngIf="isLoading" class="bd" style="text-align:center;padding:2%;">Veuillez patienter pendant la récupération de la liste de vos sujets </div>
  <ng-template matStepLabel>Choisir un végétal à équiper</ng-template>

  <mat-form-field class="example-full-width" *ngIf="!isLoading" style="display:block !important;">
    <input matInput
           placeholder="Végétal"
           aria-label="Végétal"
           name="some"
           [matAutocomplete]="auto"
           [formControl]="stateCtrl">
    <mat-autocomplete #auto="matAutocomplete" >
      <mat-option *ngFor="let vegetal of filteredStates | async" [value]="vegetal.nom_suj" (onSelectionChange)="progressU(vegetal)">
        <!-- <img class="example-option-img" aria-hidden [src]="utilsus.getIconObject(vegetal)" height="25">  -->
        <span>{{vegetal.nom_suj}}</span> - {{vegetal.nom}}

      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div>
    <button color="accent" mat-flat-button matStepperPrevious>Retour</button>
    <button style="margin-left:2%;" mat-flat-button color="primary" [disabled]="frm.invalid" matStepperNext>Suivant</button>
  </div>
</form>
</ion-label>
</ion-item>

    `

})

export class ComponentChoixVegetal {
  @Input()
  parentSubject:Subject<any>;
  @Output() changementChoixVegetal = new EventEmitter();

  frm: FormGroup;
  isLoading = true;
  listVegetaux;
  stateCtrl = new FormControl();
  filteredStates: Observable<any[]>;
  minisenseobj: any;
  constructor(private ev:EVService,private _formBuilder: FormBuilder,public utilsus: UtilsService) {
    this.frm = this._formBuilder.group({
      some: [void 0, Validators.required],
      id: [void 0, Validators.required]
    });
  }


  progressU(event){
    if (event.nom_suj) {

      this.minisenseobj.suj = event;
      this.changementChoixVegetal.emit(this.minisenseobj);
      this.frm.patchValue({some: event.nom_suj,id:event.id_suj})
    }

  }

  ngOnInit() {

    this.parentSubject.subscribe(event => {
        if (event.id_sit) {
          this.getSujetsSitelist(event.id_sit);
          this.minisenseobj = event;
        }


      // called when the notifyChildren method is
      // called in the parent component
    });
  }

  ngOnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create parentSubject on changes
    this.parentSubject.unsubscribe();
  }

  private _filterStates(value: string): any{
    console.log(value);
    const filterValue = value.toLowerCase();
    return this.listVegetaux.filter(state => state.nom_suj.toLowerCase().includes(filterValue));
  }


  getSujetsSitelist(id_site) {


    this.isLoading = false;


    this.ev.v6_liste_sujets_projet(id_site).subscribe( res => {
      this.listVegetaux = res;
       this.isLoading = false;
       this.filteredStates = this.stateCtrl.valueChanges
       .pipe(
         startWith(''),
         map(value => this._filterStates(value))
       );


    //  this.filteredStates = this.stateCtrl.valueChanges
    //  .pipe(
    //    startWith(''),
    //    map(state => state ? this._filterStates(state) : this.listVegetaux.slice())
    //  );

      }, () => {
        //this.showError('Erreur', 'Une erreur est survenue durant la réception des sites.');
        return;

    })
    // this.us.getSujetsSitesSimple(id_site)
    //   .subscribe((res: any) => {
    //  this.isLoading = false;

    //  this.listVegetaux = JSON.parse(res).vegetaux;
    //  this.filteredStates = this.stateCtrl.valueChanges
    //  .pipe(
    //    startWith(''),
    //    map(state => state ? this._filterStates(state) : this.listVegetaux.slice())
    //  );

    //   }, () => {
    //     //this.showError('Erreur', 'Une erreur est survenue durant la réception des sites.');
    //     return;
    //   });
  }
}
import { myEssenceList, dispoTypeList, arroTypeList, yearsList } from '../services/static-data';

import { MatStepper } from '@angular/material/stepper';
import { EVService } from '../services/ev.service';
import { UtilsService } from '../services/utils.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatDialog } from '@angular/material/dialog';
import { FinInstallationPage } from '../fin-installation/fin-installation.page';
import { MatInput } from '@angular/material/input';
import * as Leaflet from 'leaflet';
import { AidegeolocPage } from '../aidegeoloc/aidegeoloc.page';
import { LoaderService } from '../services/loader.service';
import { LoadingService } from '../services/loading.service';
@Component({
  selector: "choix-type-dispo",
  template: `

  <ion-item>
    <ion-label>
      <ion-text>
      <h5 *ngIf="minisenseobj?.device">Minisense {{minisenseobj?.device.device_nom}} </h5>
      </ion-text>
      <ion-text>
      <h5 *ngIf="minisenseobj?.netWork" >Connectivité : {{minisenseobj?.netWork}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.nom_sit">Projet : {{minisenseobj?.nom_sit}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.suj">Végétal : {{minisenseobj?.suj?.nom_suj}} </h5>
      </ion-text>
    </ion-label>
  </ion-item>
  <ion-item>
  <ion-label class="ion-text-wrap">
    <ion-text class="boldSf">
Choix d'un type de dispositif
</ion-text>

<form [formGroup]="frm">
  <mat-form-field class="example-full-width" *ngIf="!isLoading" style="display:block !important;">
    <input matInput
    #inputdispo
           placeholder="Type de dispositif"
           aria-label="Type de dispositif"
           formControlName="some"
           readonly
         value="{{type_dispo}}"
           [formControl]="stateCtrl">
    <mat-autocomplete #autoD="matAutocomplete" (optionSelected)='progressDispo($event.option.value)'  >
      <mat-option *ngFor="let dispotype of dispoTypeList" [value]="dispotype"    >
        <span>{{dispotype.label}}</span>

      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div>Si vous souhaitez changer le type de dispositif merci d'effectuer une maintenance  sur le sujet choisi</div>
  <div *ngIf="isAlreadyExists" class="bd" style="border:1px solid black;border-radius:5px;padding:2%;margin:2%;"><mat-icon aria-hidden="false" aria-label="Attention">warning</mat-icon>
Un minisense avec ce dispositif est deja affecté à un végétal, en continuant vous changerez cette assignation</div>
<div>
<button color="accent" mat-flat-button matStepperPrevious>Retour</button>
  <button style="margin-left:2%;" mat-flat-button color="primary"   matStepperNext>Suivant</button>
</div>
  <!-- <button (click)="showLightbox(currentDispo)" style="margin-left:2%;" mat-flat-button color="warn" [disabled]="frm.invalid"  ><mat-icon>help</mat-icon>Voir la documentation du dispositif</button> -->
</form>
</ion-label>
</ion-item>


`
})

export class ComponentChoixDispo implements OnInit {
  stateCtrl = new FormControl();
  id_minisense;
  isForced = false;
  @ViewChild('inputdispo', { static: false }) private inputdispo: MatInput;
  @Input()
  parentSubject:Subject<any>;
  currentDispo = 0;
  type_dispo:any;
  @Output() changementTypeDispo = new EventEmitter();
  frm: FormGroup;
  isLoading = true;
  panelOpenState = false;
  isAlreadyExists = false;
  listVegetaux;
  dispoTypeList: { code: string, label: string, img: string }[];
  minisenseobj: any;
  constructor(public dialog: MatDialog,private us:EVService,private _formBuilder: FormBuilder) {
    this.frm = this._formBuilder.group({
      some: [void 0, Validators.required],
      codeType: [void 0, Validators.required]
    });
  }

  togglePanel () {
      this.panelOpenState = !this.panelOpenState;
  }

  showLightbox(index) {

  // this.selectedImageIndex = index;
  // this.showFlag = true;
}



  ngOnInit(){
    this.dispoTypeList = dispoTypeList;
    this.isLoading = false;
    this.parentSubject.subscribe(event => {
        this.minisenseobj = event;

        if (this.minisenseobj.suj) {
          if (this.minisenseobj.suj.nom) {
          this.type_dispo = this.minisenseobj.suj.nom;
          this.frm.patchValue({some: this.minisenseobj.suj.nom,codeType:this.minisenseobj.suj.type_dispositif});
        }
        }
    });
  }

  ngOnDestroy() {
    this.parentSubject.unsubscribe();
  }

  progressDispo(obj :any) {


if (obj.code) {

  this.currentDispo =obj.code;
  this.minisenseobj.idMinisense = this.minisenseobj.device.device_id;
  this.minisenseobj.code = obj.code;
  this.minisenseobj.type_dispo = obj.label;

  this.frm.patchValue({some: obj.label,codeType:obj.code});

  this.us.v6_checkMinisense(this.minisenseobj).subscribe(

        result => {

          if (result != '[]') {
            this.isAlreadyExists = true;
          } else {
            this.isAlreadyExists = false;
          }
          this.changementTypeDispo.emit(this.minisenseobj);
          this.inputdispo.focused = false
        });
}


}
}


@Component({
  selector: "test-reseau",
  template: `


  <ion-item>
    <ion-label>
      <ion-text>
      <h5>Minisense : {{minisense?.device_nom}}</h5>
      </ion-text>
      <ion-text>
      <h5 *ngIf="lastTrame">Connectivité : {{lastTrame?.valid ? 'OK':'KO'}}</h5>
      </ion-text>

    </ion-label>
  </ion-item>
  <ion-item>
  <ion-label class="ion-text-wrap">

<ion-text class="boldSf">
Connectivité
</ion-text>


<p *ngIf="lastTrame?.valid">
      Félicitations, une trame vient d'être reçue par le minisense il y a {{lastTrame.intervalle}} secondes
   </p>
   <p *ngIf="!lastTrame?.valid">

       {{lastTrame?.validReason}}

   </p>

  </ion-label>




</ion-item>
<ion-item>
<form [formGroup]="frm">
<p>
<button color="accent" mat-flat-button matStepperPrevious>Retour</button>
<button  mat-flat-button color="accent" [disabled]="frm.invalid"  matStepperNext>Suivant</button></p>
</form>
<button style="margin-left:2%;" color="accent"  mat-flat-button (click)="checkNetwork(minisense)">Tester à nouveau</button>
</ion-item>
<ion-item *ngIf="lastTrame">
  <ion-label>
  <p>
   Date de la trame : {{lastTrame?.messages?.date_mysql}}
    </p>
    <p>
    Type de trame : {{lastTrame?.messages?.trame_type_txt}}
    </p>
    <p>
    Niveau de batterie : {{lastTrame?.messages?.vbat_mv / 1000}}
    </p>
    <p>
    Etat du réseau : {{lastTrame?.messages?.reseau }}
    </p>
  </ion-label>
</ion-item>
`

})

export class ComponentTestReseauMinisense implements OnInit {
  stateCtrl = new FormControl();
  frm: FormGroup;
  isLoading = true;
  @Input()
  parentSubject:Subject<any>;
  @Output() changeReseau: EventEmitter<any> = new EventEmitter<any>();
  listVegetaux;
  minisense: any;
  lastTrame: any;
  constructor(private us:EVService,private _formBuilder: FormBuilder) {
    this.frm = this._formBuilder.group({
      netWork: [void 0, Validators.required]
    });
  }

  ngOnInit(){
    this.parentSubject.subscribe(event => {

    if (event.device_id) {
      this.minisense = {device_nom:event.device_nom,device_id:event.device_id};
      this.checkNetwork( this.minisense);
    }


  });
  }

  async checkNetwork(minisense) {

    this.us.v6_check_last_trame_ev(minisense.device_nom).subscribe(res => {
      this.lastTrame = res;
      if (this.lastTrame) {
          if (this.lastTrame.intervalle) {
            if (this.lastTrame.intervalle < 43200 && this.lastTrame.intervalle > - 43200) {
              this.lastTrame.valid = true;
              this.frm.patchValue({netWork: "OK"});
              this.changeReseau.emit({netWork: "OK",device : minisense});
            } else {
              this.frm.patchValue({netWork: ""});
              this.lastTrame.valid = false;
              this.lastTrame.validReason = 'La dernière trame remontée par ce device est trop ancienne, merci de vérifier qu\'il est bien sous tension';
            }

          }else {
            this.frm.patchValue({netWork: ""});
            this.lastTrame= {valid : false};
            this.lastTrame = {validReason : 'Aucune trame remontée par ce device, merci de vérifier qu\'il est bien sous tension'};
          }

      }else {
        this.frm.patchValue({netWork: ""});
        this.lastTrame= {valid : false};
        this.lastTrame = {validReason : 'Aucune trame remontée par ce device, merci de vérifier qu\'il est bien sous tension'};
      }
      //On verifie par rapport à la date du jour que la derniere trame a moins de 10mn
    })

  }



}


@Component({
  selector: "photo-first",
  template: `





  <ion-item>
    <ion-label>
      <ion-text>
      <h5 *ngIf="minisenseobj?.device">Minisense {{minisenseobj?.device.device_nom}} </h5>
      </ion-text>
      <ion-text>
      <h5 *ngIf="minisenseobj?.netWork" >Connectivité : {{minisenseobj?.netWork}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.nom_sit">Projet : {{minisenseobj?.nom_sit}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.suj">Végétal : {{minisenseobj?.suj?.nom_suj}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.suj?.nom">Type de dispositif : {{minisenseobj?.suj?.nom}} </h5>
      </ion-text>

    </ion-label>
  </ion-item>
  <ion-item>
  <ion-label class="ion-text-wrap">
  <ion-text class="boldSf">
  Photos
  </ion-text>
  </ion-label>
</ion-item>
  <form [formGroup]="frm">

<input #imageInput type="file" class="custom-file-input" id="customFile" accept="image/*"
      (change)="processFileOne(imageInput)" style="display:none">
      <ion-item>
        <ion-label class="ion-text-wrap">
        Merci de prendre en photo l'arbre/végétal équipé avec un point de repère <b>permanent</b>.
        <ion-button (click)="imageInput.click()">
  <ion-icon slot="icon-only" name="camera"></ion-icon>
</ion-button>
        </ion-label>


      </ion-item>


      <ion-item  *ngIf="frm.value.photo_1" style="text-align:center">
      <img style="max-height:150px" src="{{frm.value.photo_1}}"/>
</ion-item>




<input #imageInputTwo type="file" class="custom-file-input" id="customFile" accept="image/*"
      (change)="processFileTwo(imageInputTwo)" style="display:none">
      <ion-item>
        <ion-label class="ion-text-wrap">Merci de prendre en photo l'arbre/végétal équipé en <b>entier</b>.  <ion-button (click)="imageInputTwo.click()">
  <ion-icon slot="icon-only" name="camera"></ion-icon>
</ion-button>
        </ion-label>


      </ion-item>

      <ion-item  *ngIf="frm.value.photo_2" style="text-align:center">
      <img style="max-height:150px"  src="{{frm.value.photo_2}}"/>
</ion-item>



<input #imageInputThree type="file" class="custom-file-input" id="customFile" accept="image/*"
      (change)="processFileThree(imageInputThree)" style="display:none">


  <ion-item>
        <ion-label class="ion-text-wrap">Merci de prendre en photo le pied avec la position du regard et un repère.
<ion-button (click)="imageInputThree.click()">
  <ion-icon slot="icon-only" name="camera"></ion-icon>
</ion-button>

</ion-label>

</ion-item>

<ion-item  *ngIf="frm.value.photo_3" style="text-align:center">
<img style="max-height:150px"  src="{{frm.value.photo_3}}"/>
</ion-item>



<input #imageInputFour type="file" class="custom-file-input" id="customFile" accept="image/*"
      (change)="processFileFour(imageInputFour)" style="display:none">
      <ion-item>
        <ion-label class="ion-text-wrap">Merci de prendre en photo la position des sondes.
<ion-button (click)="imageInputFour.click()">
  <ion-icon slot="icon-only" name="camera"></ion-icon>
</ion-button>
</ion-label>

</ion-item>

<ion-item  *ngIf="frm.value.photo_4" style="text-align:center">
<img style="max-height:150px"  src="{{frm.value.photo_4}}"/>
</ion-item>


<div>
<button style="margin-left:2%;" mat-flat-button color="primary" [disabled]="frm.invalid"  matStepperNext>Suivant</button>
</div>

</form>




    `

})

export class ComponentPhotosFirst implements OnInit {
  stateCtrl = new FormControl();
  frm: FormGroup;
  isLoading = true;
  @Input()
  parentSubject:Subject<any>;
  @Output() photos_done: EventEmitter<any> = new EventEmitter<any>();
  minisenseobj: any;

  constructor(private us:EVService,private _formBuilder: FormBuilder) {
    this.frm = this._formBuilder.group({
      photo_1: [void 0, Validators.required],
      photo_2: [void 0, Validators.required],
      photo_3: [void 0, Validators.required],
      photo_4: [void 0, Validators.required]
    });
  }

  ngOnInit(){
    this.parentSubject.subscribe(event => {
      this.minisenseobj = event;

  });
  }

  checkFrm() {

    if(this.frm.valid) {
      this.minisenseobj.photos_first = this.frm?.value;
      this.photos_done.emit(this.minisenseobj);
    }else {
      return false;
    }
  }

  async processFileOne(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();


    const config = {
      file: file,
      maxSize: 500
  };
  const resizedImage:any = await resizeImage(config)


    reader.addEventListener('load', (event: any) => {
      this.frm.patchValue({photo_1: event.target.result});

      this.checkFrm();
    });
    reader.readAsDataURL(resizedImage);
  }

  async processFileTwo(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    const config = {
      file: file,
      maxSize: 500
  };
  const resizedImage:any = await resizeImage(config)
    reader.addEventListener('load', (event: any) => {
      this.frm.patchValue({photo_2: event.target.result});
      this.checkFrm();
    });
    reader.readAsDataURL(resizedImage);
  }

  async processFileThree(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    const config = {
      file: file,
      maxSize: 500
  };
  const resizedImage:any = await resizeImage(config)
    reader.addEventListener('load', (event: any) => {
      this.frm.patchValue({photo_3: event.target.result});
      this.checkFrm();
    });
    reader.readAsDataURL(resizedImage);
  }


  async processFileFour(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    const config = {
      file: file,
      maxSize: 500
  };
  const resizedImage:any = await resizeImage(config)
    reader.addEventListener('load', (event: any) => {
      this.frm.patchValue({photo_4: event.target.result});
      this.checkFrm();
    });
    reader.readAsDataURL(resizedImage);
  }






}


@Component({
  selector: "photo-two",
  template: `
  <ion-item>
    <ion-label>
      <ion-text>
      <h5 *ngIf="minisenseobj?.device">Minisense {{minisenseobj?.device.device_nom}} </h5>
      </ion-text>
      <ion-text>
      <h5 *ngIf="minisenseobj?.netWork" >Connectivité : {{minisenseobj?.netWork}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.nom_sit">Projet : {{minisenseobj?.nom_sit}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.suj">Végétal : {{minisenseobj?.suj?.nom_suj}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.suj?.nom">Type de dispositif : {{minisenseobj?.suj?.nom}} </h5>
      </ion-text>
      <ion-text>
     <img src="{{minisenseobj?.photos_first?.photo_1}}" style="width:20%;"/>
     <img src="{{minisenseobj?.photos_first?.photo_2}}" style="width:20%;"/>
     <img src="{{minisenseobj?.photos_first?.photo_3}}" style="width:20%;"/>
     <img src="{{minisenseobj?.photos_first?.photo_4}}" style="width:20%;"/>
      </ion-text>
    </ion-label>
  </ion-item>
  <h3 class="boldSf">Fin de l'installation</h3>
  <form [formGroup]="frm">
<div>
<input #imageInput type="file" class="custom-file-input" id="customFile" accept="image/*"
      (change)="processFileOne(imageInput)" style="display:none">
<mat-label>Vous pouvez procéder maintenant au rebouchage. Merci de prendre 1 photo du chantier fini et <b>propre</b></mat-label>
<ion-button (click)="imageInput.click()">
  <ion-icon slot="icon-only" name="camera"></ion-icon>
</ion-button>
<img *ngIf="frm.value.photo_1" src="{{frm.value.photo_1}}"/>
</div>



<button style="margin-left:2%;" mat-flat-button color="accent" [disabled]="frm.invalid" (click)="sendDevice()"  >Terminer</button>

  <!-- <button style="margin-left:2%;" mat-flat-button color="primary" [disabled]="frm.invalid"  matStepperNext>Suivant</button> -->
</form>
    `

})

export class ComponentPhotosTwo implements OnInit {
  stateCtrl = new FormControl();
  frm: FormGroup;
  isLoading = true;
  @Input()
  parentSubject:Subject<any>;
  @Output() end: EventEmitter<any> = new EventEmitter<any>();
  @Output() photos_done: EventEmitter<any> = new EventEmitter<any>();
  minisenseobj: any;

  constructor(private ls:LoadingService,private ut:UtilsService,private ev:EVService,private _formBuilder: FormBuilder) {
    this.frm = this._formBuilder.group({
      photo_1: [void 0, Validators.required]
    });
  }

  sendDevice() {
console.log(this.minisenseobj);
this.minisenseobj.suj.position = this.minisenseobj.position;
this.ls.loadingPresent('Merci de patienter pendant l\'enregistrement du minisense');
    this.ev.registerMinisense(this.minisenseobj).subscribe(res =>{
      this.ls.loadingDismiss();
      if (res == true) {
          this.ut.presentToast('Bravo, votre minisense a bien été installé, pensez à faire un diagnostic pied d\'arbre pour finir.Merci',5000,'success');
          this.end.emit(true);

      }
    })
  }

  ngOnInit(){
    this.parentSubject.subscribe(event => {
    this.minisenseobj = event;

  });
  }


  checkFrm() {

    if(this.frm.valid) {
      this.minisenseobj.photos_two = this.frm?.value;
      this.photos_done.emit(this.minisenseobj);
    }else {
      return false;
    }
  }

  async processFileOne(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    const config = {
      file: file,
      maxSize: 500
  };
  const resizedImage:any = await resizeImage(config)
    reader.addEventListener('load', (event: any) => {
      this.frm.patchValue({photo_1: event.target.result});
      this.checkFrm();
    });
    reader.readAsDataURL(resizedImage);
  }

  async processFileTwo(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    const config = {
      file: file,
      maxSize: 500
  };
  const resizedImage:any = await resizeImage(config)
    reader.addEventListener('load', (event: any) => {
      this.frm.patchValue({photo_2: event.target.result});
      this.checkFrm();
    });
    reader.readAsDataURL(resizedImage);
  }

}

@Component({
  selector: "gps",
  template: `
<ion-item>
  <ion-label>
      <ion-text>
      <h5 *ngIf="minisenseobj?.device">Minisense {{minisenseobj?.device.device_nom}} </h5>
      </ion-text>
      <ion-text>
      <h5 *ngIf="minisenseobj?.netWork" >Connectivité : {{minisenseobj?.netWork}} </h5>
      </ion-text>
      <ion-text>
      <!-- <h5  *ngIf="minisenseobj?.nom_sit">Projet : {{minisenseobj?.nom_sit}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.suj">Végétal : {{minisenseobj?.suj.nom_suj}} </h5>
      </ion-text>
      <ion-text>
      <h5  *ngIf="minisenseobj?.suj?.nom">Type de dispositif : {{minisenseobj?.suj?.nom}} </h5>
      </ion-text>
      <ion-text>
     <img src="{{minisenseobj?.photos_first?.photo_1}}" style="width:20%;"/>
     <img src="{{minisenseobj?.photos_first?.photo_2}}" style="width:20%;"/>
     <img src="{{minisenseobj?.photos_first?.photo_3}}" style="width:20%;"/>
     <img src="{{minisenseobj?.photos_first?.photo_4}}" style="width:20%;"/>
      </ion-text>
      <ion-text>
     <img src="{{minisenseobj?.photos_two?.photo_1}}" style="width:20%;"/> -->

      </ion-text>
    </ion-label>
  </ion-item >
  <h3 class="boldSf">Position du sujet équipé</h3>
  <p *ngIf="geolocAvailable">Le diamètre du cercle bleu indique la précision de votre géolocalisation. Le marqueur est placé dessus mais vous pouvez l'ajustement plus finement en le déplaçant avec votre doigt si besoin.</p>
  <p *ngIf="!geolocAvailable">Nous n'avons pas pu vous localiser. Si vous souhaitez malgrè tout poursuivre merci de déplacer le marqueur avec votre doigt.</p>
  <p>Cliquez sur ce bouton si la carte s'affiche mal : <ion-button (click)="mapRefresh()" size="small">Rafraichir carte</ion-button></p>
  <form [formGroup]="frm">



<div class="map" style="width:100%;height:300px;"
     leaflet
     [leafletOptions]="options" (leafletMapReady)="onMapReady($event)">
</div>

<div>
  <!-- Position actuelle du sujet à équiper :
  <p>{{minisenseobj?.suj?.readable_position?.lat}} - {{minisenseobj?.suj?.readable_position?.lng}}</p>

  <p *ngIf="minisenseobj?.suj?.new_position">Nouvelle position: {{minisenseobj?.suj?.new_position?.lat}} - {{minisenseobj?.suj?.new_position?.lng}}</p> -->
 <ion-button (click)="validatePos()">Valider la position</ion-button>
 <!-- <ion-button [disabled]="!geolocAvailable" (click)="getPosition()">Utiliser ma position</ion-button> -->
 <!-- <ion-button (click)="editPosition = true;markerActuel.dragging.enable();">Editer la position</ion-button> -->
</div>


 <p> <button color="accent" mat-flat-button matStepperPrevious>Retour</button>
<button style="margin-left:2%;" [disabled]="frm.invalid" mat-flat-button color="primary"   matStepperNext>Suivant</button></p>

  <!--  -->
</form>
    `

})

export class ComponentGps implements OnInit {

  havePosition = false;
  gettingPos= false;
  stateCtrl = new FormControl();
  frm: FormGroup;
  newMarker: any;
  hasMoved=false;
  editPosition=false;
  markerActuel:any;
  userPosition;
  circle:Leaflet.circle;
  markerFutur ;
  geolocAvailable = false;
  isLoading = true;
  @Input()
  parentSubject:Subject<any>;

  minisenseobj: any;
  options = {
    layers: [
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      })
    ],
    zoom: 7,
    center: Leaflet.latLng([ 48.85, 2.34 ])
  };
  map: Leaflet.Map;

  constructor(public readonly geolocation$: GeolocationService,private ev:EVService,private ut:UtilsService,private us:EVService,private _formBuilder: FormBuilder) {
    this.frm = this._formBuilder.group({
      position: [void 0, Validators.required]
    });
    if (this.map) {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 0);
    }

  }

  mapRefresh() {
    setTimeout(() => {
      this.map.invalidateSize();
    }, 0);
  }

  validatePos() {
    let curPos = this.markerActuel.getLatLng();
debugger;
      this.minisenseobj.position = "("+curPos.lat +", "+curPos.lng +"):18";

    this.frm.patchValue({position: {lat : curPos.lat, lng :curPos.lng}});
  }



  handleError(error) {
    this.geolocAvailable = false;
    if (this.map) {
      if(this.markerActuel) {
        this.markerActuel.remove();
      }
        this.markerActuel = Leaflet.marker([48.85, 2.34], {draggable:'true',icon: Leaflet.icon( {
          iconUrl: '../../assets/UrbaNewPictos/Vieux_arbres/vieux_arbres_default.png',
          iconSize: [40, 40],
          iconAnchor: [20, 20],
          popupAnchor: [0, -28]
        })}).addTo(this.map);
    }
  }

  displayPosition (position) {
    this.geolocAvailable = true;
    if (this.map) {
      var radius = position.coords.accuracy/2;
      if(this.circle) {
        this.circle.remove();
      }
      if(!this.markerActuel) {
        this.markerActuel = Leaflet.marker([position.coords.latitude,position.coords.longitude], {draggable:'true',icon: Leaflet.icon( {
          iconUrl: '../../assets/UrbaNewPictos/Vieux_arbres/vieux_arbres_default.png',
          iconSize: [40, 40],
          iconAnchor: [20, 20],
          popupAnchor: [0, -28]
        })}).addTo(this.map);
      }



      this.circle = Leaflet.circle([position.coords.latitude,position.coords.longitude], radius).addTo(this.map);
      //this.map.panTo(new Leaflet.LatLng(position.coords.latitude,position.coords.longitude))
      this.map.flyTo([position.coords.latitude,position.coords.longitude], 18);
    }

  }

  getPosition(){

      // this.gettingPos = true;
      // this.map.locate({ setView: true }).on("locationfound", (e: any) => {
      //   this.gettingPos = false;

      //   var radius = e.accuracy/2;
      //   if(this.circle) {
      //     this.circle.remove();
      //   }
      //   this.circle = Leaflet.circle(e.latlng, radius).addTo(this.map);
      //   this.havePosition = true;

        //this.markerActuel.setLatLng(new Leaflet.LatLng(e.latitude,e.longitude),{draggable:'false'});

  //     }).on("locationerror", (e: any) => {
  //       this.ut.presentToast('Impossible de récupérer votre position, merci de vérifier que vous  avez autorisé le site à accéder à celle ci',4000,'danger');
  //       this.gettingPos = false;

  //  console.log(e);
  //     });

  }


  setPosition() {
alert('set pos');
//  this.hasMoved=true;
//   this.markerFutur.setLatLng(new Leaflet.LatLng(this.userPosition.lat,this.userPosition.lng),{draggable:'false'});
//   this.map.panTo(new Leaflet.LatLng(this.userPosition.lat,this.userPosition.lng))
    //this.frm.patchValue({position: this.minisenseobj.suj.readable_position});

  }



  ngOnInit(){
    this.geolocation$.subscribe(position => this.displayPosition(position),error => this.handleError(error));

    this.parentSubject.subscribe(event => {
    this.minisenseobj = event;

    if ( this.minisenseobj.suj) {

      // if(this.markerActuel) {
      //   this.markerActuel.remove();
      // }

      // this.minisenseobj.suj.readable_position = this.ut.parsePosition(this.minisenseobj.suj.position);

      // this.markerActuel = Leaflet.marker([this.minisenseobj.suj.readable_position.lat, this.minisenseobj.suj.readable_position.lng], {draggable:'true',icon: Leaflet.icon( {
      //   iconUrl: '../../assets/UrbaNewPictos/Vieux_arbres/vieux_arbres_default.png',
      //   iconSize: [40, 40],
      //   iconAnchor: [20, 20],
      //   popupAnchor: [0, -28]
      // })}).addTo(this.map);
      // this.map.panTo(new Leaflet.LatLng(this.minisenseobj.suj.readable_position.lat, this.minisenseobj.suj.readable_position.lng));


      // this.markerActuel.on('dragend', (event)=>{
      //   this.markerFutur = event.target;
      //   var position = this.markerFutur.getLatLng();

      //   // this.minisenseobj.suj.new_position = position;

      //   this.hasMoved=true;

      // });

    }


  });
  }


  onMapReady(map: Leaflet.Map) {
    this.map = map;
    setTimeout(() => {
      map.invalidateSize();
    }, 0);


  }



}

















