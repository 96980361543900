
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ConnectionService } from 'ng-connection-service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ProgressComponent} from './components/progress/progress.component';
import { LoaderService } from './services/loader.service';
import { UtilsService } from './services/utils.service';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment as env } from '../environments/environment';
import de from '@angular/common/locales/de';
registerLocaleData(localeFr);
registerLocaleData(de);

declare var require: any;



import 'moment/locale/fr';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MixpanelService } from './services/mixpanel.service';
import { LangageService } from './services/langage.service';
import { UrbasenseHttpRequestInterceptor } from './services/urbasensehttpinterceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent,ProgressComponent],
  entryComponents: [],

  imports: [BrowserModule,NgSelectModule,HttpClientModule,    FormsModule,
    ReactiveFormsModule, TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }
  }),    AuthModule.forRoot({
    ...env.auth,
    httpInterceptor: {
      ...env.httpInterceptor,
    },
  }), IonicModule.forRoot(), AppRoutingModule, BrowserAnimationsModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), NgbModule],
  providers: [
    MixpanelService,
    ConnectionService,
    LangageService,
    LoaderService,
    UtilsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    [
      { provide: HTTP_INTERCEPTORS, useClass: UrbasenseHttpRequestInterceptor, multi: true }
  ],
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: navigator.language},

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent]
})
export class AppModule {}
