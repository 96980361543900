import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class EVService {

  constructor(private _http:HttpClient) { }
  public getElectrovannes() {
    return this._http.get(environment.api_url + 'electrovannes');
  }

  public v6_projets() {
    return this._http.get(environment.api_url + 'projets');
  }

  public v6_checkUser() {
    return this._http.get(environment.api_url + 'checkUser');
  }


  public v6_projet_compteurs(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/compteur');
  }
  public v6_projets_actualite() {
    return this._http.get(environment.api_url + 'projets/actualite');
  }

  public v6_maintenance_projet(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/maintenance');
  }

  public v6_infos_compteur(id_compteur) {
    return this._http.get(environment.api_url + 'compteur/' + id_compteur);
  }

  public v6_liste_sujets_projet(id_compteur) {
    return this._http.get(environment.api_url + 'liste_sujets_projets/' + id_compteur);
  }

  public v6_check_last_trame_ev(id_device) {
    return this._http.get(environment.api_url + 'equipement/lasttrame/' + id_device);
  }

  public v6_uploadImage(image: File,id_suj) {

   
      const formData = new FormData();
      formData.append('image', image);
      const req = new HttpRequest('POST', environment.api_url + 'sujet/' + id_suj + '/image', formData, { reportProgress: true });
      return this._http.request<any>(req);

     
     //return this._http.request(req);
     // console.log(image);
     // const formData = new FormData();
     // formData.append('image', image);
 
     // return this._http.post<any>(this.apiURL + 'uploadPics/' + id_sit + '', formData, {
     //   reportProgress: true,
      
     //   observe: 'events'
     // }).pipe(map((event) => {
     
     //   switch (event.type) {
           
     //     case HttpEventType.UploadProgress:
     //       console.log('eveent progress');
     //       const progress = Math.round(100 * event.loaded / event.total);
     //       return { status: 'progress', message: progress };
 
     //     case HttpEventType.Response:
     //       console.log('eveent ' + event.body);
     //       return event.body;
     //     default:
     //       return `Unhandled event: ${event.type}`;
     //   }
     // }));
 
   
  }

  

  public v6_projets_bulletins(position) {
    return this._http.get(environment.api_url + 'projets/bulletins/' + position);
  }

  public v6_sujet_update(sujet) {
    return this._http.post(environment.api_url + 'sujet' , JSON.stringify(sujet));
  }

  public v6_setPhotoRotation(photo: any) {
    const req = this._http.post<any>(environment.api_url + 'rotationphoto', JSON.stringify(photo));
    return req;
  }

  

  public v6_secteur_graphe(id_secteur) {
    return this._http.get(environment.api_url + 'secteur/'+ id_secteur +'/graphe');
  }

  public v6_sujet_create(sujet) {
    return this._http.post(environment.api_url + 'sujet/nouveau' , JSON.stringify(sujet));
  }

  public archiveSujet(sujet) {
    return this._http.post(environment.api_url + 'sujet/archive' , JSON.stringify(sujet));
  }

  public v6_setDureeSujet(sujet) {
    return this._http.post(environment.api_url + 'sujet/duree' , JSON.stringify(sujet));
  }


  

  public v6_masquePhoto(suj_id_raw: number, myfile_raw: string) {
    return this._http.get(environment.api_url  + 'photo/masquer/' + suj_id_raw + '/' + myfile_raw + '');
  }

  

  public v6_projet_liste_secteur_ev(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/secteur_ev_liste');
  }

  public v6_projet_liste_secteur(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/secteur_liste');
  }

  

  public v6_projet_programmation(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/programmation');
  }

  public v6_secteur_programmation(id_secteur) {
    return this._http.get(environment.api_url + 'secteur/'+id_secteur+'/programmation');
  }

  public v6_projet_entete(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/entete');
  }

  


  

  public v6_projet_programmation_historique(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/programmation_historique');
  }
  public v6_secteur_programmation_historique(id_secteur) {
    return this._http.get(environment.api_url + 'secteur/'+id_secteur+'/programmation_historique');
  }


  

  

  public v6_voies_secteurs(listeSecteurs) {
    return this._http.get(environment.api_url + 'voies/'+JSON.stringify(listeSecteurs));
  }

  public v6_reprogrammer(listeVoies){
    return this._http.put(environment.api_url + 'reprogrammer/' , JSON.stringify(listeVoies));
  }

  

  

  public v6_setMinisense(qrcode: any) {
    const req = this._http.put<any>(environment.api_url + 'minisense/', qrcode);
    return req;
  }

  public v6_enrollMinisense(qrcode: any) {
    const req = this._http.post<any>(environment.api_url + 'minisense/', qrcode);
    return req;
  }

  public v6_checkMinisense(obj: any) {
    const req = this._http.post<any>(environment.api_url + 'minisensecheck/', JSON.stringify(obj));
    return req;
  }

  public v6_urbadata() {
    return this._http.get<any>(environment.api_url + 'urbadata');
  }

  public v6_device_info(id_device) {
    return this._http.get<any>(environment.api_url + 'device/' + id_device);
  }

  public v6_updateSujetsDuGroupe(liste) {
    const req = this._http.post<any>(environment.api_url + 'secteur/composition', liste);
    return req;
  }


  public getRemarquesSujet(idSujet: number) {
    return this._http
      .get(environment.api_url + 'sujet/' + idSujet + '/remarques');
  }

  public getDiagnosticsSujet(idSujet: number) {
    return this._http
      .get(environment.api_url + 'sujet/' + idSujet + '/diagnostics');
  }

  

  

  public v6_projets_photos(position) {
    return this._http.get(environment.api_url + 'projets/photos/' + position);
  }


  public v6_projets_interventions() {
    return this._http.get(environment.api_url + 'projets/interventions');
  }

  public v6_setDiagPiedArbre(diagnosis) {
    return this._http.post(environment.api_url + 'sujet/diagnostic' , JSON.stringify(diagnosis));
  }

  public v6_sujet_remarque(remarque) {
    return this._http.post(environment.api_url + 'sujet/remarque' , JSON.stringify(remarque));
  }

  public v6_projet_bulletins(id_projet,position) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/bulletins/' + position);
  }

  public v6_secteurs_electrovannes(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/secteurs/electrovannes');
  }

  public v6_secteur_bulletins(id_secteur,position) {
    return this._http.get(environment.api_url + 'secteur/'+id_secteur+'/bulletins/' + position);
  }

  public v6_secteur_bulletins_frise(id_secteur) {
    return this._http.get(environment.api_url + 'secteur/'+id_secteur+'/bulletins_frise');
  }

  public v6_projet_bulletins_frise(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/bulletins_frise');
  }


  

  public v6_renommer_secteur(data) {
    return this._http.put(environment.api_url + 'secteur/renommer' , JSON.stringify(data));
  }

  public v6_secteurs_equipements(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/secteurs/equipements');
  }

  public v6_projet_historique_ev(id_projet,position) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/historique_ev/' + position);
  }

  public v6_projet_contacts(id_projet) {
    return this._http.get(environment.api_url + 'projet/'+id_projet+'/contacts');
  }

  public v6_projet_historique_ev_secteur(id_secteur) {
    return this._http.get(environment.api_url + 'secteur/'+id_secteur+'/historique_ev');
  }

  

  public registerMinisense(obj) {
    return this._http.put(environment.api_url + 'minisense' , JSON.stringify(obj));
  }

  

  public v6_secteur_equipements(id_secteur) {
    return this._http.get(environment.api_url + 'secteur/'+id_secteur+'/equipements');
  }

  public v6_detail_bulletin(id_bulletin) {
    return this._http.get(environment.api_url + 'bulletin/'+id_bulletin);
  }

  public v6_programmations(id_projet) {
    return this._http.get(environment.api_url + 'programmations/' + id_projet);
  }

  public v6_programmation_delete(id_programmation) {
    return this._http.delete(environment.api_url + 'programmation/'+id_programmation);
  }

  public v6_secteur_remarque (id_secteur,remarque) {
    return this._http.put(environment.api_url + 'secteur/'+id_secteur+ '/remarque/' , JSON.stringify(remarque));
  }

  

  public linkCompteur(compteur) {
  
    return this._http.put(environment.api_url + 'link_compteur/',JSON.stringify(compteur));
  }

  public unlink_compteur(compteur) {
  
    return this._http.put(environment.api_url + 'unlink_compteur/',JSON.stringify(compteur));
  }
  

  

  public v6_compteur_save(compteur,id_projet) {
    compteur = {
      'compteur' : compteur.nom
    }
    return this._http.put(environment.api_url + 'compteur/' + id_projet,JSON.stringify(compteur));
  }

  public v6_programmation_save(programmation,id_projet) {
    programmation = {
      'nom' : programmation.nom,
      'contenu' : {
          'duree' : programmation.duree,
          'depart' : programmation.depart,
          'frequence' : programmation.frequence,
          'planning' : programmation.planning
      }
    }
    return this._http.put(environment.api_url + 'programmation/' + id_projet,JSON.stringify(programmation));
  }


  public v6_programmation_secteur_save(id_secteur,id_programmation) {
    let programmation = {
      'id_secteur' : id_secteur,
    'id_programmation' : id_programmation
    
    }
    return this._http.put(environment.api_url + 'programmation_secteur',JSON.stringify(programmation));
  }



  

  public v6_programmation_detail(id_programme) {
    return this._http.get(environment.api_url + 'programmation/' + id_programme);
  }

  

  

  
  
  public v6_projet(idProjet: number) {
    return this._http
      .get(environment.api_url + 'projet/' + idProjet + '')
  }
  public v6_secteur(id_secteur: number) {
    return this._http
      .get(environment.api_url + 'secteur/' + id_secteur + '');
  }
  public v6_sujet(id_sujet: number) {
    return this._http
      .get(environment.api_url + 'sujet/' + id_sujet + '');
  }

  public v6_sujet_edition(id_sujet: number) {
    return this._http
      .get(environment.api_url + 'sujet/' + id_sujet + '/edition');
  }
  async v6_projet_overlay(id_projet: number): Promise<any> {
    return this._http
      .get(environment.api_url + 'projet/'+id_projet+'/carte')
      .toPromise();
  }

  public v6_projet_geojson(id_projet: number) {
    return this._http
      .get(environment.api_url + 'projet/'+id_projet+'/geojson');
  }

  
 

  
}
