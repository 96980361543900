import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit {
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  hidden = false;
  randomNumber = 0;
  arrayConseils=[{'title':'','body':'Les groupes sont les unités homogènes d\'arrosage. Ils sont constitués de sujets (arbres ou autres) qui sont à traiter selon les mêmes modalités.'},
  {'title':'Maîtriser son arrosage','body':'Urbasense exploite les capacités de la sonde tensiométrique, afin de proposer un service de suivi de la qualité racinaire des plantations et de l\'efficacité des arrosages'},
  {'title':'Voir la quantité d\'eau disponible aux racines pour un sujet','body':'Urbasense exploite les capacités de la sonde tensiométrique, afin de proposer un service de suivi de la qualité racinaire des plantations et de l\'efficacité des arrosages'}]
  constructor(private loaderService: LoaderService,public router:Router) { 


  }

  ngOnInit() {
    this.randomNumber= Math.floor(Math.random()*this.arrayConseils.length);
  }

}
