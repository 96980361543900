import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { SuperadminGuard } from './superadmin.guard';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules
};
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full'
  },


  {
    path: 'place-detail',
    loadChildren: () => import('./place-detail/place-detail.module').then( m => m.PlaceDetailPageModule),
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'detail-projet',
    loadChildren: () => import('./home/detail-projet/detail-projet.module').then( m => m.DetailProjetPageModule),
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'photos',
    loadChildren: () => import('./photos/photos.module').then( m => m.PhotosPageModule),
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'interventions',
    loadChildren: () => import('./interventions/interventions.module').then( m => m.InterventionsPageModule)
  },
  {
    path: 'sites',
    loadChildren: () => import('./sites/sites.module').then( m => m.SitesPageModule)
  },
  {
    path: 'actualites',
    loadChildren: () => import('./actualites/actualites.module').then( m => m.ActualitesPageModule),
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },




  {
    path: 'home/interventions',
    redirectTo: '/home/actualites/interventions',
    pathMatch: 'full'
  },
  {
    path: 'home/photos',
    redirectTo: '/home/actualites/photos',
    pathMatch: 'full'
  },
  {
    path: 'minisense',
    loadChildren: () => import('./minisense/minisense.module').then( m => m.MinisensePageModule)
  },
  {
    path: 'tensio',
    loadChildren: () => import('./tensio/tensio.module').then( m => m.TensioPageModule)
  },
  {
    path: 'ev',
    loadChildren: () => import('./ev/ev.module').then( m => m.EvPageModule)
  },
  {
    path: 'installminisense',
    loadChildren: () => import('./installminisense/installminisense.module').then( m => m.InstallminisensePageModule)
  },
  {
    path: 'detail-bulleti',
    loadChildren: () => import('./detail-bulletin/detail-bulletin.module').then( m => m.DetailBulletinPageModule)
  },
  {
    path: 'reprogrammer',
    loadChildren: () => import('./reprogrammer/reprogrammer.module').then( m => m.ReprogrammerPageModule)
  },
  {
    path: 'liste-programmes',
    loadChildren: () => import('./liste-programmes/liste-programmes.module').then( m => m.ListeProgrammesPageModule)
  },
  {
    path: 'modifications',
    loadChildren: () => import('./modifications/modifications.module').then( m => m.ModificationsPageModule)
  },
  {
    path: 'urbadata',
    loadChildren: () => import('./urbadata/urbadata.module').then( m => m.UrbadataPageModule)
  },
  {
    path: 'cahier-arrosage',
    loadChildren: () => import('./cahier-arrosage/cahier-arrosage.module').then( m => m.CahierArrosagePageModule)
  }




  ,
  {
    path: 'detail-projet/:id_projet/agro/liste/detail-secteur/:id_secteur',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/projet-watering/projet-programmation/reprogrammer-projet',
    redirectTo: 'detail-projet/:id_projet/projet-watering/projet-programmation/reprogrammer-projet',
    pathMatch: 'full'
  },

  {
    path: 'detail-projet/:id_projet/projet-watering/liste-programme/nouveau-programme',
    redirectTo: 'detail-projet/:id_projet/projet-watering/liste-programme/nouveau-programme',
    pathMatch: 'full'
  },








  {
    path: 'detail-projet/:id_projet/detail-secteur/:id_secteur/agro/liste/detail-sujet/:id_sujet',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur/detail-sujet/:id_sujet',
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always' ,
    pathMatch: 'full'
  },
  {

    path: 'detail-projet/:id_projet/agro/liste/detail-sujet/:id_sujet',
    redirectTo: 'detail-projet/:id_projet/agro/detail-sujet/:id_sujet',
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always' ,
    pathMatch: 'full'
  },


  {
    path: 'detail-projet/:id_projet/detail-secteur/:id_secteur/liste',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur/agro/liste',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/detail-secteur/:id_secteur/watering',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur/agro/watering',
    pathMatch: 'full'
  },

  {
    path: 'detail-projet/:id_projet/detail-secteur/:id_secteur/agro/watering/modifications',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur/modifications',
    pathMatch: 'full'
  },

  {
    path: 'detail-projet/:id_projet/detail-secteur/:id_secteur/agro/watering/reprogrammer',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur/reprogrammer',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/detail-secteur/:id_secteur/liste-programme',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur/liste-programme',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/detail-secteur/:id_secteur/secteur-programmation',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur/liste-programme',
    pathMatch: 'full'
  },



  {
    path: 'detail-projet/:id_projet/liste-programme',
    redirectTo: 'detail-projet/:id_projet/projet-watering/liste-programme',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/historique-ouverture',
    redirectTo: 'detail-projet/:id_projet/projet-watering/historique-ouverture',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/bulletins',
    redirectTo: 'detail-projet/:id_projet/agro/bulletins',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/liste',
    redirectTo: 'detail-projet/:id_projet/agro/liste',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/map',
    redirectTo: 'detail-projet/:id_projet/agro/map',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/equipement',
    redirectTo: 'detail-projet/:id_projet/projethardware/equipement',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/maintenance',
    redirectTo: 'detail-projet/:id_projet/projethardware/maintenance',

    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/projet-programmation',
    redirectTo: 'detail-projet/:id_projet/projet-watering/projet-programmation',
    pathMatch: 'full'
  },

  {
    path: 'detail-projet/:id_projet/projet-contacts',
    redirectTo: 'detail-projet/:id_projet/projet-contacts',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/liste-programme',
    redirectTo: 'detail-projet/:id_projet/projet-watering/liste-programme',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/historique-programme',
    redirectTo: 'detail-projet/:id_projet/projet-watering/historique-programme',
    pathMatch: 'full'
  },





  {
    path: 'detail-projet/:id_projet/detail-secteur/:id_secteur/historique-programme-secteur',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur/historique-programme-secteur',
    pathMatch: 'full'
  },
  {
    path: 'detail-projet/:id_projet/detail-secteur/:id_secteur/historique-ouverture-secteur',
    redirectTo: 'detail-projet/:id_projet/detail-secteur/:id_secteur/historique-ouverture-secteur',
    pathMatch: 'full'
  },


  {
    path: 'detail-projet/:id_projet/projet-programmation',
    redirectTo: 'detail-projet/:id_projet/projet-watering/projet-programmation',
    pathMatch: 'full'
  },

  {
    path: 'detail-projet/:id_projet/historique-programme',
    redirectTo: 'detail-projet/:id_projet/projet-watering/historique-programme',
    pathMatch: 'full'
  },

  {
    path: 'detail-projet/:id_projet/ajouter',
    redirectTo: 'detail-projet/:id_projet/projethardware/ajouter',
    pathMatch: 'full'
  },


















  {
    path: 'listevegetauxsecteur',
    loadChildren: () => import('./listevegetauxsecteur/listevegetauxsecteur.module').then( m => m.ListevegetauxsecteurPageModule)
  },
  {
    path: 'listevegetaux',
    loadChildren: () => import('./listevegetaux/listevegetaux.module').then( m => m.ListevegetauxPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'equipement-detail',
    loadChildren: () => import('./equipement-detail/equipement-detail.module').then( m => m.EquipementDetailPageModule)
  },
  {
    path: 'secteur-hardware',
    loadChildren: () => import('./secteur-hardware/secteur-hardware.module').then( m => m.SecteurHardwarePageModule)
  },
  {
    path: 'equipement-secteur',
    loadChildren: () => import('./equipement-secteur/equipement-secteur.module').then( m => m.EquipementSecteurPageModule)
  },
  {
    path: 'cahier-arrosage',
    loadChildren: () => import('./cahier-arrosage/cahier-arrosage.module').then( m => m.CahierArrosagePageModule)
  },
  {
    path: 'projet-watering',
    loadChildren: () => import('./projet-watering/projet-watering.module').then( m => m.ProjetWateringPageModule)
  },
  {
    path: 'projet-programmation',
    loadChildren: () => import('./projet-programmation/projet-programmation.module').then( m => m.ProjetProgrammationPageModule)
  },
  {
    path: 'liste-programme',
    loadChildren: () => import('./liste-programme/liste-programme.module').then( m => m.ListeProgrammePageModule)
  },
  {
    path: 'historique-programme',
    loadChildren: () => import('./historique-programme/historique-programme.module').then( m => m.HistoriqueProgrammePageModule)
  },
  {
    path: 'projet-watering',
    loadChildren: () => import('./projet-watering/projet-watering.module').then( m => m.ProjetWateringPageModule)
  },
  {
    path: 'secteur-programmation',
    loadChildren: () => import('./secteur-programmation/secteur-programmation.module').then( m => m.SecteurProgrammationPageModule)
  },
  {
    path: 'gestion-interventions',
    loadChildren: () => import('./gestion-interventions/gestion-interventions.module').then( m => m.GestionInterventionsPageModule)
  },
  {
    path: 'nouveau-programme',
    loadChildren: () => import('./nouveau-programme/nouveau-programme.module').then( m => m.NouveauProgrammePageModule)
  },
  {
    path: 'historique-ouverture',
    loadChildren: () => import('./historique-ouverture/historique-ouverture.module').then( m => m.HistoriqueOuverturePageModule)
  },
  {
    path: 'detail-compteur',
    loadChildren: () => import('./detail-compteur/detail-compteur.module').then( m => m.DetailCompteurPageModule)
  },
  {
    path: 'historique-bulletin-secteur',
    loadChildren: () => import('./historique-bulletin-secteur/historique-bulletin-secteur.module').then( m => m.HistoriqueBulletinSecteurPageModule)
  },
  {
    path: 'sujet-edition',
    loadChildren: () => import('./sujet-edition/sujet-edition.module').then( m => m.SujetEditionPageModule)
  },
  {
    path: 'nouveau-diagnostic',
    loadChildren: () => import('./nouveau-diagnostic/nouveau-diagnostic.module').then( m => m.NouveauDiagnosticPageModule)
  },
  {
    path: 'nouvelle-remarque',
    loadChildren: () => import('./nouvelle-remarque/nouvelle-remarque.module').then( m => m.NouvelleRemarquePageModule)
  },
  {
    path: 'historique-programme-secteur',
    loadChildren: () => import('./historique-programme-secteur/historique-programme-secteur.module').then( m => m.HistoriqueProgrammeSecteurPageModule)
  },
  {
    path: 'historique-ouverture-secteur',
    loadChildren: () => import('./historique-ouverture-secteur/historique-ouverture-secteur.module').then( m => m.HistoriqueOuvertureSecteurPageModule)
  },
  {
    path: 'projet-contacts',
    loadChildren: () => import('./projet-contacts/projet-contacts.module').then( m => m.ProjetContactsPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },
  {
    path: 'upload-photo',
    loadChildren: () => import('./upload-photo/upload-photo.module').then( m => m.UploadPhotoPageModule)
  },
  {
    path: 'fin-installation',
    loadChildren: () => import('./fin-installation/fin-installation.module').then( m => m.FinInstallationPageModule)
  },
  {
    path: 'installation',
    loadChildren: () => import('./installation/installation.module').then( m => m.InstallationPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'installminisensefin',
    loadChildren: () => import('./installminisensefin/installminisensefin.module').then( m => m.InstallminisensefinPageModule)
  },
  {
    path: 'ajout-vegetal',
    loadChildren: () => import('./ajout-vegetal/ajout-vegetal.module').then( m => m.AjoutVegetalPageModule)
  },
  {
    path: 'infos-sujet',
    loadChildren: () => import('./infos-sujet/infos-sujet.module').then( m => m.InfosSujetPageModule)
  },
  {
    path: 'map-sujet',
    loadChildren: () => import('./map-sujet/map-sujet.module').then( m => m.MapSujetPageModule)
  },
  {
    path: 'graphe-secteur',
    loadChildren: () => import('./graphe-secteur/graphe-secteur.module').then( m => m.GrapheSecteurPageModule)
  },
  {
    path: 'erreur',
    loadChildren: () => import('./erreur/erreur.module').then( m => m.ErreurPageModule)
  },
  {
    path: 'editionphoto',
    loadChildren: () => import('./editionphoto/editionphoto.module').then( m => m.EditionphotoPageModule)
  },
  {
    path: 'aidegeoloc',
    loadChildren: () => import('./aidegeoloc/aidegeoloc.module').then( m => m.AidegeolocPageModule)
  },
  {
    path: 'external/:token/sujet/:idsujet',
    loadChildren: () => import('./external/external.module').then( m => m.ExternalPageModule)
  },
  {
    path: 'globalmap',
    loadChildren: () => import('./globalmap/globalmap.module').then( m => m.GlobalmapPageModule)
  },
  {
    path: 'graphe',
    loadChildren: () => import('./graphe/graphe.module').then( m => m.GraphePageModule)
  },
  {
    path: 'reprogrammation-new',
    loadChildren: () => import('./reprogrammation-new/reprogrammation-new.module').then( m => m.ReprogrammationNewPageModule)
  },
  {
    path: 'ajout-compteur',
    loadChildren: () => import('./ajout-compteur/ajout-compteur.module').then( m => m.AjoutCompteurPageModule)
  },
  {
    path: 'link-compteur-new',
    loadChildren: () => import('./link-compteur-new/link-compteur-new.module').then( m => m.LinkCompteurNewPageModule)
  },
  {
    path: 'adminarbre',
    loadChildren: () => import('./adminarbre/adminarbre.module').then( m => m.AdminarbrePageModule),
    canActivate: [SuperadminGuard], data: {
      expectedRole: 'SuperAdmin'
      },
      runGuardsAndResolvers: 'always'

  }
  //,
  // {
  //   path: 'detail-projet/:id_projet/agro/liste',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'equipement',
  //   loadChildren: () => import('./equipement/equipement.module').then( m => m.EquipementPageModule)
  // }


  //Test nico



  // ,
  // {
  //   path: 'projethardware',
  //   loadChildren: () => import('./projethardware/projethardware.module').then( m => m.ProjethardwarePageModule)
  // },
  // {
  //   path: 'liste',
  //   loadChildren: () => import('./liste/liste.module').then( m => m.ListePageModule)
  // },
  // {
  //   path: 'historique-bulletin',
  //   loadChildren: () => import('./historique-bulletin/historique-bulletin.module').then( m => m.HistoriqueBulletinPageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,routingConfiguration)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
