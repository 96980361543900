import { ModalController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AidegeolocPage } from '../aidegeoloc/aidegeoloc.page';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  today: string;

  constructor(private toastController:ToastController, private modalController:ModalController) { }

  public getValeur(sujet?: any) {
    if (sujet && sujet.teadexpert) {
      if (sujet.teadexpert.moisture) {
        return Math.ceil(sujet.teadexpert.moisture * 100);
      }else {
        return 0;
      }
    } else {
      return 0;
    }
  }




  public async presentModalHelp() {
    const modal = await this.modalController.create({
      component: AidegeolocPage,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }



  public getGaugeColor(sujet?: any) {
    let color = 'black';
    if (sujet && sujet.teadexpert) {
      if (sujet.teadexpert.moisture) {
 if ((sujet.teadexpert.moisture) > 0.9) {
        color = '#406AE0';
      } else if ((sujet.teadexpert.moisture) >= 0.5 && (sujet.teadexpert.moisture) <=  0.9) {
        color = 'rgb(85, 194, 119)';
      } else if ((sujet.teadexpert.moisture) >= 0.1 && (sujet.teadexpert.moisture)< 0.5) {
        color = '#FAB51A';
      } else if ((sujet.teadexpert.moisture) <  0.1) {
        color = 'red';
      }
    }
    }
    return color;
  }

  public getValeurS(sujet?: any) {
    //On doit extraire dernier element de la collection

    if (sujet && sujet.txp_moisture.result) {
      if (sujet.txp_moisture.result.moistures) {

      if (sujet.txp_moisture.result.moistures.length > 0) {

        return Math.ceil(sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1] * 100);
      }else {
        return 0;
      }
    } else {
      return 0;
    }
    } else {
      return 0;
    }
  }

  public getGaugeColorS(sujet?: any) {
    let color = 'black';
    if (sujet && sujet.txp_moisture.result) {
      if (sujet.txp_moisture.result.moistures) {
      if (sujet.txp_moisture.result.moistures.length > 0) {
 if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) > 0.9) {
        color = '#406AE0';
      } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) >= 0.5 && (sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) < 0.9) {
        color = 'rgb(85, 194, 119)';
      } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) >= 0.1 && (sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1])< 0.5) {
        color = '#FAB51A';
      } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) <  0.1) {
        color = 'red';
      }
    }
    }
  }
    return color;
  }

  public ConvertToInt(val){
    return parseInt(val);
  }

  public parsePosition(pos: string): any {
    let ret:any= {
      lat: 0,
      lng: 0,
      zoom: 0,
    };
    const zoom_ = Number(
      pos
        .split(':')[1]
        .replace(/[^0-9]/, '')
        .trim(),
    );
    var lat_ = pos
      .split(':')[0]
      .split(',')[0]
      .replace(/[^0-9]/, '')
      .trim();

      lat_  = Number.parseFloat(lat_.slice(0, lat_.length)).toFixed(8);
    var lng_ = pos
      .split(':')[0]
      .split(',')[1]
      .replace(/[^0-9]/, '')
      .trim();

      lng_ = Number.parseFloat(lng_.slice(0, lng_.length)).toFixed(8);
if (Number.parseFloat(lat_) < 180 && Number.parseFloat(lng_) < 180) {
   ret = {
    lat: Number.parseFloat(lat_),
    lng: Number.parseFloat(lng_),
    zoom: zoom_,
  };
}


    return ret;
  }

  analyseSonde (sujet) {
    let abberrantValue = false;
    sujet.trame_avg = 720;
    if (sujet.trames) {
      if (sujet.trames[0]) {
        if (sujet.trames[0].messages) {
            if (sujet.trames[0].messages.decode.length > 0){
              // sujet.trames[0].messages.decode.forEach(function (value) {
              //   if (value.valeur >= 255) {
              //     abberrantValue = true;
              //   }
              // })
              let index = 1;
              for (var i = 0; i < sujet.trames[0].messages.decode.length; i++) {
                     if (sujet.trames[0].messages.decode[i].valeur >= 255) {
                      abberrantValue = true;


                }
                if (index >= sujet.nb_sond) {
                  break;
                 } else  {
                   index = index+1;
                 }
              }
            }
      var date1 = new Date(sujet.trames[0].messages.date_mysql);
      var date2 = new Date(sujet.trames[sujet.trames.length - 1].messages.date_mysql);
      var diff = this.diff_minutes(date1,date2);
      sujet.trame_avg = diff/sujet.trames.length;
    }
  }
  }
  sujet.aberrantValeur = abberrantValue;
  return sujet;
   }

   diff_minutes(dt2, dt1) {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
   }

  public getDeviceInfoSynchro(sujet,typeAnalyse) {
    var d = new Date();
    d.setDate(d.getDate() - 2);
    this.today =d.toISOString();
    sujet = this.analyseSonde(sujet);

    let  styles = {
      'color': 'red'
    };
    let text ="";
      if (sujet.trames) {
        if (sujet.trames[0]) {

        if (sujet.trames[0].messages.date_mysql > this.today && sujet.centrale && sujet.trame_avg > 960) {
          styles = {
            'color': 'orange'
          };

          text = "Cet icône signifie le minisense a remonté un message dans les dernières 48h mais que leur fréquence est trop faible";
        } else if ((sujet.trames[0].messages.date_mysql < this.today && sujet.centrale || sujet.trames.length == 0) && sujet.centrale != '0STATION') {
          styles = {
            'color': 'red'
          };

          text = "Cet icône signifie le minisense n'a pas remonté de message dans les dernières 48h";
        } else if (sujet.trames[0].messages.date_mysql >= this.today && sujet.centrale && sujet.trame_avg <= 960) {
          styles = {
            'color': '#55c277'
          };
          text = "Cet icône signifie que le minisense est parfaitement synchronisé";
        }
      }
    }
    if (typeAnalyse != 'tooltip') {
      return styles;
    }else {
      return text;
    }

  }


  async presentToast(message,duration,color) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: color,
      position : 'top'
    });
    toast.present();
  }

  public afficherIconeEauDispoRacine(sujet?: any) {

    let color = 'empty';
    let ret: string;
    if (sujet && sujet.txp_moisture) {
    if (sujet.txp_moisture.result) {
      if (sujet.txp_moisture.result.moistures) {
      if (sujet.txp_moisture.result.moistures.length > 0) {
 if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) > 0.9) {
        color = 'blue';
      } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) >= 0.5 && (sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) < 0.9) {
        color = 'green';
      } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) >= 0.1 && (sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1])< 0.5) {
        color = 'yellow';
      } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1]) <  0.1) {
        color = 'red';
      }
    }
    }
  }
}
    ret = 'assets/' + 'pin_default_' + color + '.png';
    if (sujet.picto_type) {
      if (sujet.picto_type === 'defaut') {
        ret = 'assets/' + 'pin_default_' + color + '.png';
      } else if (sujet.picto_type === 'arbre') {
        ret = 'assets/UrbaNewPictos/Jeune_pousse/jeune_pousse_' + color + '.png';
      } else if (sujet.picto_type === 'vivace') {
        ret = 'assets/UrbaNewPictos/Fleur_gazon/fleur_gazon_' + color + '.png';
      } else if (sujet.picto_type === 'pilote') {
        ret = 'assets/UrbaNewPictos/Electrovanne/electrovanne_' + color + '.png';
      } else if (sujet.picto_type === 'sport') {
        ret = 'assets/UrbaNewPictos/Terrain_sport/terrain_sport_' + color + '.png';
      }
    }


    return ret;
  }

  public getIconObject(sujet?: any) {

    let color = 'empty';
    let ret: string;
    if (sujet && sujet.txp_moisture) {
      if (sujet && sujet.txp_moisture.result) {
      if (sujet.txp_moisture.result.moisture) {
 if ((sujet.txp_moisture.result.moisture) > 0.9) {
        color = 'blue';
      } else if ((sujet.txp_moisture.result.moisture) >= 0.5 && (sujet.txp_moisture.result.moisture) < 0.9) {
        color = 'green';
      } else if ((sujet.txp_moisture.result.moisture) >= 0.1 && (sujet.txp_moisture.result.moisture)< 0.5) {
        color = 'yellow';
      } else if ((sujet.txp_moisture.result.moisture) <  0.1) {
        color = 'red';
      }
    }
  }
    }
    ret = 'assets/' + 'pin_default_' + color + '.png';
    if (sujet.picto_type) {
      if (sujet.picto_type === 'defaut') {
        ret = 'assets/' + 'pin_default_' + color + '.png';
      } else if (sujet.picto_type === 'arbre') {
        ret = 'assets/UrbaNewPictos/Jeune_pousse/jeune_pousse_' + color + '.png';
      } else if (sujet.picto_type === 'vivace') {
        ret = 'assets/UrbaNewPictos/Fleur_gazon/fleur_gazon_' + color + '.png';
      } else if (sujet.picto_type === 'pilote') {
        ret = 'assets/UrbaNewPictos/Electrovanne/electrovanne_' + color + '.png';
      } else if (sujet.picto_type === 'sport') {
        ret = 'assets/UrbaNewPictos/Terrain_sport/terrain_sport_' + color + '.png';
      }
    }


    return ret;
  }
}
