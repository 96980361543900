import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class LangageService {

  constructor(private  translate: TranslateService) { 
    translate.addLangs(['fr', 'en','de','it','pt','es','ar']);
    if(localStorage.getItem('language')){
      translate.setDefaultLang(localStorage.getItem('language'));
      translate.use(localStorage.getItem('language'));
  }else {
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/fr|de|es|pt|it|ar|en/) ? browserLang : 'en');
 
    
  }
  }

  public setLangage(language) {
    localStorage.setItem('language',language);
    this.translate.use(localStorage.getItem('language'));
  }

  public getLangage () {
    if(localStorage.getItem('language')){
    
      return(localStorage.getItem('language'));
  }else {
    const browserLang = this.translate.getBrowserLang();
    return(browserLang.match(/fr|de|es|pt|it|ar|en/) ? browserLang : 'en');
 
    
  }

  }
}
